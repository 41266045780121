@import url(./design-system.css);

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

img{
  display: block;
}

body{
  font-family: var(--ffMain);
  font-size: 14px;
  line-height: 28px;
  color:var(--clrDarkGrey);
  background: var(--clrLightBeige);
}

/*Navbar*/
.navbar-wrapper{
  width: 1440px;
  margin:0 auto;
  padding:40px 40px 0 40px;
  background: #fff;
  padding-bottom: var(--paddingBottomNav);
}

.navbar-inner{
  width: 100%;
  display:flex;
  justify-content: space-between;
  border-bottom: 1px solid #979797;
  padding-bottom: 40px;
}

.navbar-logo-container{
  width: 170px;
  height: 48px;
}

.navbar-logo-container img{
  width: 100%;
  object-fit: cover;
}

.thumbnail-text-overlay{
  width: 246px;
}

.thumbnail-text-overlay h2{
  margin-bottom: 7px;
}

.main-page-masonry{
  width: 1440px;
  height: 1606px;
  column-count: 4;
  column-gap: 40px;
  margin: 0 auto;
  padding:0 40px;
  background: #fff;
}

.thumbnail-body{
  position: relative;
  display: block;
  break-inside: avoid-column;
  width: 310px;
  height: auto;
  margin-bottom: 40px;
  cursor: pointer;
  transition: all var(--trStandard);
}

.thumbnail-image-container {
  max-width: 100%;
  height: auto;
}

.thumbnail-image{
  max-width: 100%;
  display: block;
}

.thumbnail-body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(255,255,255, 0.5), rgba(0, 0, 0, 0.85));
  opacity: 0;
  transition: opacity var(--trStandard);
}

.thumbnail-body:hover::before {
 opacity: 1;
}

.thumbnail-text-overlay{
  position: absolute;
  color:#fff;
  left:32px;
  bottom:32px;
}

/*Painting Card*/
.painting-card-wrapper{
  width: 1440px;
  height: auto;
  margin: 0 auto;
  padding: 0 40px;
  background: #fff;
}

.painting-card-body{
  width: 1360px;
  height: 624px;
  position: relative;
  margin-bottom: var(--marginBottomCard);
}

.view-image-btn{
  position: absolute;
  height: 40px;
  width: 152px;
  border:none;
  background:rgba(0,0,0,0.60);
  bottom:16px;
  left:16px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:14px;
  cursor: pointer;
  font-family: inherit;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  color:#fff;
  transition: all var(--trStandard);
}

.view-image-btn:hover{
  background: rgba(255,255,255,0.25);
}

.view-image-container p{
  text-transform: uppercase;
}

.card-img-container{
  width: 475px;
  height: 560px;
  position: relative;
}

.gallery-image{
  width: 100%;
  object-fit: contain;
}

.gallery-title-container{
  position: absolute;
  width: 445px;
  height: 302px;
  top:0;
  left:410px;
  background: #fff;
}

.gallery-title-container h1{
  margin-bottom: 24px;
  margin-left:65px;
}

.gallery-title-container p{
  margin-left: 65px;
}

.artist-image-container{
  width: 128px;
  height: 128px;
  position: absolute;
  bottom:-320px;
  left:100px;
}

.artist-image-container img{
  width: 100%;
  object-fit: contain;
}

.description-container{
  position: absolute;
  width: 350px;
  max-height: max-content;
  top:115px;
  right:125px;
}

.description-container p {
  font-weight: 700;
}

.year{
  position: absolute;
  top:0;
  right:0;
  font-size: 200px;
  font-weight: 700;
  line-height: 150px;
  color:var(--clrLightGrey);
}

.source-link{
  position: absolute;
  right:373px;
  bottom: 53px;
  width: max-content;
}

/*Card Navigation*/
.card-nav-container{
  width: 100%;
  height: 96px;
  position: relative;
}

.card-nav{
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  border-top:1px solid var(--clrMediumGrey);
}

.card-nav-container::before{
  position: absolute;
  top:-1px;
  left:0;
  content: '';
  height: 1px;
  width: var(--progressPercentage);
  background: #000;
}

.card-nav-text-container h3{
margin-bottom: 8px;
}

.card-nav-buttons{
  display: flex;
  align-items: center;
  gap:40px;
}

.icon-nav{
  cursor: pointer;
}

/*Modal*/
.modal-overlay{
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,0.85);
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-overlay.active{
  visibility: visible;
  opacity: 1;
}

.modal-image-container{
  height: 95%;
  max-width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modal-image-container img{
  max-height: 100%;
  max-width: 100%;
  display: block;
  object-fit: cover;
}

/*Responsive Design*/
 /*Tablet*/
@media screen and (max-width:1420px) {
  .navbar-wrapper{
    width: 768px;
    padding:40px 40px 0 40px;
    border-bottom: 1px solid #979797;
  }

  .navbar-inner{
    border:none;
    padding-bottom: 24px; 
  }

  .navbar-logo-container{
    width: 113px;
    height: 32px;
  }

  .main-page-masonry{
    width: 768px;
    height: 2989px;
    column-count: 2;
    padding-top: 40px;
  }

  .thumbnail-body{
    width: 324px;
  } 
 
    /*Painting Card*/
    .painting-card-wrapper{
      width: 768px;
      padding-top: 40px;
    }

    .painting-card-body{
      height: 1084px;
      margin-bottom: 0;
    }

    .gallery-title-container{
      width: 445px;
      height: auto;
      left:243px;
    }

    .gallery-title-container h1{
      margin-bottom: 24px;
    }

    .artist-name {
      margin-bottom: 67px;
    }

    .artist-image-container{
     left:260px;
     bottom:-128px;
    }
 
    .year{
      left:24px;
      top:620px;
    }

    .description-container{
      width: 457px;
      left:115px;
      top:700px;
      }

    .source-link{
      left:115px;
      bottom: 65px;
    }

    .card-nav-container{
      width: 768px;
     margin-left:-40px;
    }

    .card-nav{
      padding: 0 40px;
    }
  }

/*Mobile*/
@media screen and (max-width:740px){
   /*Mobile Navbar*/
  .navbar-wrapper {
    width: 375px;
    padding: 24px 24px 0 24px;
  }

  .navbar-logo-container{
    width: 113px;
    height: 32px;
  }
  
  .btn-primary{
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 2px;
  }

  /*Mobile Masonry*/
  .main-page-masonry{
    width: 375px;
    height: auto;
    column-count: 1;
    padding: 0 24px;
  }

  .thumbnail-body{
    width: 327px;
    margin: auto;
    padding-top: 24px;
  }

  .thumbnail-body:last-child{
   padding-bottom: 24px;
  }

  /*Mobile Painting Card*/
  .painting-card-wrapper{
    width: 375px;
    height: auto;
    padding: 24px 24px 0 24px;
  }

  .painting-card-body{
    width: 100%;
    height: 1000px;
  }

  .card-img-container{
    width: 327px;
    height: 280px;
  }

  .view-image-btn{
    top:16px;
    left:16px;
  }

  .gallery-title-container{
    width: 280px;
    height: 120px;
    left:0;
    top:300px;
  }

  .gallery-title-container h1{
    font-size: 24px;
    line-height: 29px;
    margin:24px 24px 8px 24px;
  }

  .gallery-title-container p {
    margin-left: 24px;
  }

  .artist-image-container{
    height: 64px;
    width: 64px;
    left:12px;
    bottom:-64px;
  }

  .year{
    font-size: 100px;
    line-height: 100px;
    top:450px;
    left:90px;
  }

  .description-container {
    width: 327px;
    left:0;
    top:515px;
  }

  .source-link{
    left:0;
    bottom: 50px;
  }

  .card-nav-container{
    width: 375px;
    height: 72px;
    margin-left: -24px;
  }

  .card-nav{
    padding: 24px;
  }

  .card-nav-text-container h3{
    font-size: 14px;
    line-height: 17px;
    color:#000;
  }

  .subhead2-mobile{
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color:#000;
  }

  .card-nav-buttons{
    gap:16px;
  }

  .icon-nav{
    transform: scale(0.6538, 0.6667);
  }
  
}

