@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');

:root{
  /*Colors*/
  --clrDarkGrey:#7D7D7D;
  --clrMediumGrey:#E5E5E5;
  --clrLightGrey:#F3F3F3;
  --clrLightBeige:#f2f2f2;

  /*Font-Family*/
  --ffMain:'Libre Baskerville', serif;

 /*Margin, Padding, Gap*/
 --paddingBottomNav:40px;
 --paddingBottomNavMobile:24px;
 --marginBottomCard:40px;

  /*Transitions*/
  --trStandard:0.15s ease-out;
}

/*Typography*/

h1{
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;
  color:#000;
}

h2{
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}

a{
  color:var(--clrDarkGrey);
  text-transform: uppercase;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 2px;
}

a:hover{
  color:#000;
  transition: all var(--trStandard);
}

.subhead1{
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  color:var(--clrDarkGrey);
}

.subhead2{
  font-size: 13px;
  line-height: 17px;
  color:#000;
}

.subhead3{
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 2px;
  color:#fff;
}

.thumbnail-artist{
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  opacity: 0.75;
}

.card-nav-title{
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color:#000;
}

/*Buttons*/
.btn{
  border: none;
  background: none;
  font-weight: 700;
  text-align: center;
  font-family:inherit;
  text-transform: uppercase;
  color:var(--clrDarkGrey);
  transition:all var(--trStandard);
}

.btn-primary{
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 2.571px;
}

.btn-secondary{
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 2px;
}

.btn-modal{
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 4px;
  color:#fff;
}

.btn:hover{
  color:#000;
}

.btn-modal:hover{
  color:#fff;
}